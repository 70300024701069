<template>
  <van-cell-group :title="title" inset>
    <van-grid :column-num="3" style="margin: 8px">
      <van-grid-item v-for="(item,index) in state.images" :key="index">
        <van-image :src="item.path" @click="onImageClick(index)"/>
      </van-grid-item>
    </van-grid>
<!--    <van-cell v-for="(item,index) in state.others" :key="index" :title="item.name" :url="item.path"-->
<!--              title-style="color:#1989fa" />-->
    <!-- 文档预览 不下载 -->
    <div v-for="(item,index) in state.others" :key="index"
         :style="'color:#1989fa;padding:0 15px'" @click="onfileClick(item.path)" >
      {{item.name}}
    </div>
  </van-cell-group>
</template>

<script setup>
import {reactive, onUpdated} from "vue";
import {ImagePreview} from "vant";

const props = defineProps({
  title: {type: String, default: '附件', required: false},
  files: {type: Array, default: [], required: true}
});

const state = reactive({images:[], others:[]});

onUpdated(() => {
  const images = [];
  const others = [];
  props.files.forEach(file => file.type.indexOf("image") > -1 ? images.push(file) : others.push(file));
  state.images = images;
  state.others = others;
})

const onfileClick = (url) => {
  console.log(url)
  window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url))
}


const onImageClick = index => ImagePreview({
  images: state.images.map(image => image.path),
  startPosition: index,
  closeable: true
})
</script>
